import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { fetchAuthUser } from '../store/actions'

const AuthHeader = dynamic(() => import('./AuthHeader'))
const GuestHeader = dynamic(() => import('./GuestHeader'))

const Header = () => {
    const authUser = useSelector(({ authUser }) => authUser)
    const dispatch = useDispatch()
    const router = useRouter()
    const isFixed = router.pathname === '/registry/[registryUrl]'

    useEffect(() => {
        dispatch(fetchAuthUser())
    }, [])

    return authUser
        ? <AuthHeader fixed={isFixed}/>
        : <GuestHeader fixed={isFixed}/>
}

export default Header
